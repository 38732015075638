const Uppy = require("@uppy/core")
const uppyGerman = require("@uppy/locales/lib/de_DE")
const uppyEnglish = require("@uppy/locales/lib/en_US")
const uppyEspanol = require("@uppy/locales/lib/es_ES")
const Dashboard = require("@uppy/dashboard")
const DefaultStore = require("@uppy/store-default")
const XHRUpload = require('@uppy/xhr-upload')

const initUppy = (element_id) => {
  const uppyElement = document.querySelector("#" + element_id)

  if (uppyElement == undefined) {
    return false
  }

  const appLocale = document.querySelector("body").dataset.language
  let uppyLocale = uppyEnglish
  let uppyDropPaste = 'On a Desktop-PC you can upload photos by drag-and-drop, copy-paste or click the %{browse} button. On a mobile device you can add photos from your gallery or the camera.'

  if(appLocale.toLowerCase() == "de") {
    uppyDropPaste = 'Die Bilder können am Desktop über Drag/Drop, Einfügen bzw. %{browse} oder am Mobiltelefon über die Galerie bzw. Kamera eingefügt werden.'
    uppyLocale = uppyGerman
  }

  if(appLocale.toLowerCase() == "es") {
    uppyDropPaste = 'Las imágenes se pueden insertar mediante arrastrar/soltar o copiar/pegar %{browse}. También se puede subir imágenes del teléfono móvil a través de la galería o la cámara.'
    uppyLocale = uppyEspanol
  }

  const uppy = Uppy({
    id: element_id,
    autoProceed: false,
    allowMultipleUploads: true,
    debug: true,
    restrictions: {
      maxFileSize: 10*1024*1024,
      maxNumberOfFiles: uppyElement.dataset.maxNumberOfFiles,
      minNumberOfFiles: null,
      allowedFileTypes: ['image/jpeg', 'image/jpg']
    },
    meta: {},
    onBeforeFileAdded: (currentFile, files) => currentFile,
    onBeforeUpload: (files) => {},
    locale: uppyLocale,
    store: new DefaultStore(),
    logger: Uppy.debugLogger
  })
  uppy.use(Dashboard, {
    id: 'Dashboard',
    locale: {
      strings: {
        dropPaste: uppyDropPaste,
      },
    },
    target: '#uppy',
    metaFields: [],
    inline: true,
    width: 750,
    height: 550,
    thumbnailWidth: 280,
    showLinkToFileUploadResult: true,
    showProgressDetails: false,
    hideUploadButton: false,
    hideRetryButton: false,
    hidePauseResumeButton: false,
    hideCancelButton: false,
    hideProgressAfterFinish: false,
    note: null,
    closeModalOnClickOutside: false,
    closeAfterFinish: false,
    disableStatusBar: false,
    disableInformer: false,
    disableThumbnailGenerator: false,
    disablePageScrollWhenModalOpen: true,
    animateOpenClose: true,
    proudlyDisplayPoweredByUppy: true,
    showSelectedFiles: true,
    browserBackButtonClose: false
  })

	uppy.use(XHRUpload, {
    endpoint: '/images/upload', // Shrine's upload endpoint
  })

  var hiddenInputTemplate = document.querySelector('#uppy-hidden-field-template')

  uppy.on('upload-success', function (file, response) {
    // read uploaded file data from the upload endpoint response
    var uploadedFileData = JSON.stringify(response.body)

    // set hidden field value to the uploaded file data so that it's submitted with the form as the attachment
    var hiddenInput = hiddenInputTemplate.cloneNode(true)
    hiddenInput.value = uploadedFileData
    hiddenInput.id = false
    hiddenInputTemplate.parentNode.appendChild(hiddenInput)
  })

  uppy.on('complete', function (result) {
    hiddenInputTemplate.remove()
    const formElement = uppyElement.closest('form')
    formElement.submit()
  })
}

export default initUppy
