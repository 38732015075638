import { Controller } from 'stimulus'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy'

export default class extends Controller {
  static targets = []

  connect() {
    tippy(this.element, {
      allowHTML: true
    })
  }
}
