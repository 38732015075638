var loading_indicator_timeout;

export const initLightbox = () => {
  var LEFT_ARROW_KEY = 37
  var RIGHT_ARROW_KEY = 39
  var ESCAPE_KEY = 27

  var current_image = undefined

  var gallery_images = document.querySelectorAll(".gallery_image")
  var gallery_modal = document.querySelector('#gallery_modal')
  var gallery_close_modal = document.querySelector("#close_modal")
	var gallery_modal_background = document.querySelector(".modal-background")

  var gallery_previous_image = document.querySelector(".gallery_previous_image")
  var gallery_next_image = document.querySelector(".gallery_next_image")

  var loading_indicator = document.querySelector(".loading_indicator")

  if (gallery_modal == undefined) { return false }

  for(let i = 0; i < gallery_images.length; i++) {
    const element = gallery_images[i]
    const index = i

    element.addEventListener("click", function(e) {
      e.preventDefault()
      display_loading_indicator(loading_indicator)

      if(gallery_images.length > 1) {
        gallery_previous_image.classList.remove('is-hidden')
        gallery_next_image.classList.remove('is-hidden')
      } else {
        gallery_previous_image.classList.add('is-hidden')
        gallery_next_image.classList.add('is-hidden')
      }

      const clicked_image = e.target
      const description = clicked_image.dataset.lightboxDescription
      const original_image_source = clicked_image.parentNode.getAttribute("href")

			gallery_modal.classList.add('is-active')
      document.querySelector("html").classList.add('is-clipped')
      set_modal_image(gallery_modal, original_image_source, description, function() {
        hide_loading_indicator(loading_indicator)
      })
      current_image = index
    })
  }

	gallery_modal.addEventListener("click", function() {
		close_modal(gallery_modal)
	})
	
	gallery_modal.querySelector("img").addEventListener("click", function(e) {
		e.preventDefault()
		e.stopPropagation()	
	})

  gallery_previous_image.addEventListener("click", function(e) {
		e.stopPropagation()	
		current_image = load_previous_image(gallery_images, current_image, loading_indicator, gallery_modal)
  })

  gallery_next_image.addEventListener("click", function(e) {
		e.stopPropagation()	
		current_image = load_next_image(gallery_images, current_image, loading_indicator, gallery_modal)
  })

  document.addEventListener("keyup", function(e) {
		// if(!gallery_modal.classList.contains('is-active')) { return false }

		if (e.keyCode == LEFT_ARROW_KEY) {
			current_image = load_previous_image(gallery_images, current_image, loading_indicator, gallery_modal)
		} else if (e.keyCode == RIGHT_ARROW_KEY) {
			current_image = load_next_image(gallery_images, current_image, loading_indicator, gallery_modal)
		} else if (e.keyCode == ESCAPE_KEY) {
			const activeModal = document.querySelector(".modal.is-active")
			close_modal(activeModal)
		}
  }, { passive: true })

  gallery_close_modal.addEventListener("click", function() {
   	close_modal(gallery_modal) 
  })

  function close_modal(modal) {
		modal.classList.remove('is-active')
		document.querySelector("html").classList.remove('is-clipped')
  }

  function display_loading_indicator(indicator) {
    loading_indicator_timeout = window.setTimeout(function() {
      indicator.classList.remove("is-hidden")
    }, 200)
  }

  function hide_loading_indicator(indicator) {
    clearTimeout(loading_indicator_timeout)
    indicator.classList.add("is-hidden")
  }

  function set_modal_image(modal, image_source, description, callback_function) {
    var image_element = modal.querySelector("img")
    var paragraph = modal.querySelector("p")
    image_element.setAttribute("src", image_source)
    image_element.onload = function() {
      paragraph.textContent = description
      callback_function()
    }
  }

  function load_previous_image(gallery_images, current_image, loading_indicator, gallery_modal) {
    display_loading_indicator(loading_indicator)

    if (current_image-1 < 0) {
      current_image = gallery_images.length - 1
    } else {
      current_image = current_image - 1
    }

    var original_image_source = gallery_images[current_image].parentNode.getAttribute("href")
    var description = gallery_images[current_image].dataset.lightboxDescription

    set_modal_image(gallery_modal, original_image_source, description, function() {
      hide_loading_indicator(loading_indicator)
    })

    return current_image
  }

  function load_next_image(gallery_images, current_image, loading_indicator, gallery_modal) {
    display_loading_indicator(loading_indicator)

    if (current_image+1 > gallery_images.length-1) {
      current_image = 0
    } else {
      current_image = current_image + 1
    }

    var original_image_source = gallery_images[current_image].parentNode.getAttribute("href")
    var description = gallery_images[current_image].dataset.lightboxDescription

    set_modal_image(gallery_modal, original_image_source, description, function() {
      hide_loading_indicator(loading_indicator)
    })

    return current_image
  }
}
