import ClipboardJS from 'clipboard'

const initClipboard = () => {
  const copyToClipboardSelector = ".copy-to-clipboard"

  const clipboard = new ClipboardJS(copyToClipboardSelector)

  clipboard.on('success', (e) => {
    const button = e.trigger
    const defaultIcon = button.querySelector(".clipboard-icon-default")
    const successIcon = button.querySelector(".clipboard-icon-success")

    if(successIcon) {
      defaultIcon.classList.add('is-hidden')
      successIcon.classList.remove('is-hidden')

      window.setTimeout(() => {
        defaultIcon.classList.remove('is-hidden')
        successIcon.classList.add('is-hidden')
      }, 900)
    }
  })

  clipboard.on('error', (e) => {
    const button = e.trigger
    const defaultIcon = button.querySelector(".clipboard-icon-default")
    const errorIcon = button.querySelector(".clipboard-icon-error")

    if(successIcon) {
      defaultIcon.classList.add('is-hidden')
      errorIcon.classList.remove('is-hidden')

      window.setTimeout(() => {
        defaultIcon.classList.remove('is-hidden')
        errorIcon.classList.add('is-hidden')
      }, 900)
    }
  })
}

export default initClipboard
