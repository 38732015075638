const initializeGeolocation = (buttonSelector, getPositionSuccess, getPositionFailure) => {
  const buttons = document.querySelectorAll(buttonSelector)
  const geolocationOptions = {
    timeout: 10*1000,
    maximumAge: 5 * 60 * 1000,
  }

  if (buttons.length == 0) return false
  if (!navigator.geolocation) return false

  buttons.forEach( (button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault()

      document.querySelector(".get-current-position-error").classList.add('is-hidden')

      button.querySelector(".spinner").classList.remove('is-hidden')
      button.querySelector(".crosshair-icon").classList.add('is-hidden')

      navigator.geolocation.getCurrentPosition(getPositionSuccess, getPositionFailure, geolocationOptions);
    })
  })
}

export default initializeGeolocation
