import mergeWith from "lodash/mergeWith"

const initFoldOutMenu = (options) => {

  const defaultOptions = {
    selectors: {
      button: '[data-fold-out-menu-button]',
      content: '[data-fold-out-menu-content]'
    },
    classes: {
      opened: "opened",
      closed: "closed"
    }
  }

  options = mergeWith(defaultOptions, options)

  const foldOutMenuToggles = document.querySelectorAll(defaultOptions.selectors.button)
  const foldOutMenuContents = document.querySelectorAll(defaultOptions.selectors.content)

  if(foldOutMenuToggles == undefined || foldOutMenuContents == undefined) {
    return false
  }

  for (var i = 0; i < foldOutMenuToggles.length; i++) {
    const foldOutMenuToggle = foldOutMenuToggles[i]
    const foldOutMenuContent = foldOutMenuToggle.parentElement.querySelector(options.selectors.content)

    foldOutMenuToggle.addEventListener("click", e => {
      e.preventDefault()

      if( foldOutMenuContent.classList.contains(defaultOptions.classes.closed) ){
        foldOutMenuContent.classList.remove(defaultOptions.classes.closed)
        foldOutMenuContent.classList.add(defaultOptions.classes.opened)
      } else {
        foldOutMenuContent.classList.remove(defaultOptions.classes.opened)
        foldOutMenuContent.classList.add(defaultOptions.classes.closed)
      }
    })
  }
}

export default initFoldOutMenu
