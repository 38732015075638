const initModals = () => {
  var ESCAPE_KEY = 27

  const modalButtons = document.querySelectorAll("[data-modal-target]")

  for(let i = 0; i < modalButtons.length; i++) {
    const button = modalButtons[i]

    button.addEventListener("click", (e) => {
      e.preventDefault()
      const modalSelector = button.dataset.modalTarget
      const modal = document.querySelector(modalSelector)

      if (modal == undefined) return false

      modal.classList.add('is-active')
      document.querySelector("html").classList.add('is-clipped')
      modal.querySelector('.modal-close').addEventListener('click', (e) => {
        e.preventDefault()

        closeModal(modal)
      })
      modal.querySelector('.modal-abort-btn').addEventListener('click', (e) => {
        e.preventDefault()

        closeModal(modal)
      })
    })
  }

  const closeModal = (modal) => {
    modal.classList.remove('is-active')
    document.querySelector("html").classList.remove('is-clipped')
  }
}

export default initModals
