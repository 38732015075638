const initCountrySelects = () => {
  const countrySelects = document.querySelectorAll('select[data-countries-with-states]')

  for(let i = 0; i < countrySelects.length; i++) {
    const element = countrySelects[i]
    const targetStateSelectSelector = element.dataset["targetStateSelector"]
    const targetStateSelect = document.querySelector(targetStateSelectSelector)

    element.addEventListener("change", (e) => {
      e.preventDefault()
      const newOptions = []
      const countriesWithStates = JSON.parse(e.target.dataset["countriesWithStates"])
      const value = e.target.value
      const statesByCountry = countriesWithStates[e.target.value]

      statesByCountry.forEach((state) => {
        newOptions.push('<option value="' + state[0] + '">' + state[1] + '</option>')
      })

      targetStateSelect.innerHTML = newOptions.join()
    })
  }
}

export default initCountrySelects
