import * as L from "leaflet"
import debounce from 'lodash/debounce'
import { setLatLng } from './map'

const moveHandler = (event, map, marker) => {
  const coordinates = map.getCenter()
  marker.setLatLng(coordinates)
  setLatLng({ lat: coordinates.lat, lng: coordinates.lng })
}

const initLocationAutocomplete = (map) => {
  const locationAutocompleteInput = document.querySelector('#location-autocomplete')
  if (locationAutocompleteInput == undefined) {
    return false
  }

  const addressTemplate = document.querySelector("#location-autocomplete-template")
  const addressList = document.querySelector("#location-autocomplete-list")
  const geocodeUrl = addressList.dataset.geocodeUrl
  const spinner = locationAutocompleteInput.parentElement.querySelector(".spinner") 
  const emptyListButton = document.querySelector("#location-autocomplete-list-empty") 

  emptyListButton.addEventListener('click', (e) => {
    e.preventDefault()

    const listEntries = document.querySelectorAll("#location-autocomplete-list li:not(#location-autocomplete-template)")
    listEntries.forEach( elem => elem.remove() )
    emptyListButton.classList.add('is-hidden')
  })

  const debouncedFn = debounce((e) => {

    const value = e.target.value
    const csrfToken = document.querySelector("[name='csrf-token']").getAttribute('content')

    spinner.classList.remove('is-hidden')

    fetch(geocodeUrl, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        address: value
      }),

    }).then(function(response) {
      return response.json()
    }).then(function(locations) {
      spinner.classList.add('is-hidden')
      emptyListButton.classList.remove('is-hidden')

      const listEntries = document.querySelectorAll("#location-autocomplete-list li:not(#location-autocomplete-template)")
      listEntries.forEach( elem => elem.remove() )

      locations.forEach( (location) => {
        let dupItem = addressTemplate.cloneNode()
        dupItem.dataset.location = JSON.stringify(location)
        dupItem.addEventListener('click', (e) => {
          const locationData = JSON.parse(e.target.dataset.location)
          const newLatLng = new L.LatLng(locationData.lat, locationData.lng)
          let hasMarkers = false

          map.eachLayer((layer) => {
            if(layer instanceof L.Marker) {
              hasMarkers = true
              layer.setLatLng(newLatLng)
            }
          })

          if (hasMarkers == false) {
            const marker = L.marker(locationData)
            marker.addTo(map)

            map.on("move", (event) => moveHandler(event, map, marker))
          }

          map.setView(newLatLng, 15)
          setLatLng({ lat: locationData.lat, lng: locationData.lng })

          const listEntries = document.querySelectorAll("#location-autocomplete-list li:not(#location-autocomplete-template)")
          listEntries.forEach( elem => elem.remove() )
          emptyListButton.classList.add('is-hidden')
        })
        dupItem.id = null
        dupItem.classList.remove('is-hidden')
        dupItem.innerText = location.full_address
        addressList.appendChild(dupItem)
      })
    })

  }, 500)
  locationAutocompleteInput.addEventListener('keyup', debouncedFn, { passive: true })
}

export default initLocationAutocomplete
