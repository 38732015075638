import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  connect() {
    const deleteButton = this.element.querySelector('.delete')
    deleteButton.addEventListener('click', (e) => {
      this.element.remove() 
    })
  }
}
