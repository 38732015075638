import throttle from 'lodash/throttle'

const initImageDescriptionHint = () => {
  const descriptionInput = document.querySelector("#soil_sample_image_description")
  const descriptionLengthHint = document.querySelector("[data-truncated-description-length]")
  const descriptionLengthValue = document.querySelector("[data-truncated-description-length] span")

  if (descriptionInput == undefined || descriptionLengthHint == undefined) {
    return false
  }

  const truncatedDescriptionLength = descriptionLengthHint.dataset.truncatedDescriptionLength

  const calculateCharactersLeft = () => {
    const currentLength = descriptionInput.value.length
    const charactersLeft = truncatedDescriptionLength - currentLength
    descriptionLengthValue.textContent = charactersLeft
  }

  descriptionInput.addEventListener("keyup", throttle(calculateCharactersLeft, 350), { passive: true })

  calculateCharactersLeft()
}

export default initImageDescriptionHint
