import { Controller } from "stimulus";
import Sortable from "sortablejs";
import { put } from "@rails/request.js";

export default class extends Controller {
  static targets = ["item"];
  static values = { url: String };

  connect() {
    const csrf_token = document.querySelector("[name='csrf-token']").getAttribute("content");

    this.sortable = new Sortable(this.element, {
      draggable: "[data-sortable-target='item']",
      ghostClass: "has-dragndrop-ghost",
      onUpdate: (event) => {
        const dragged_item = event.item;
        const old_index = event.oldIndex;
        const new_index = event.newIndex;

        put(this.urlValue, {
          responseKind: "turbo-stream",
          body: JSON.stringify({
            item_id: dragged_item.dataset.itemId,
            old_index: old_index,
            new_index: new_index,
          }),
        })
      },
    });
  }

  disconnect() {
    this.sortable.destroy();
  }
}
