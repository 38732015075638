import { Controller } from "stimulus"

export default class extends Controller {
  static values = { displayOn: String }
  static targets = [ "stringInput" ]

  connect() {
    this.select = this.element.querySelector("select")
    this.toggle()
  }

  change(e) {
    this.toggle()
  }

  toggle() {
    if (this.displayOnValue == this.select.value) {
      this.stringInputTarget.classList.remove("is-hidden")
    } else {
      this.stringInputTarget.classList.add("is-hidden")
    }
  }
}
