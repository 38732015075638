const initTopsoilColors = () => {
  // clickable topsoil colors instead of radio buttons
  const topsoilColorElements = document.querySelectorAll(".topsoil-color")

  for(let i = 0; i < topsoilColorElements.length; i++) {
    const element = topsoilColorElements[i]

    element.addEventListener("click", (e) => {
      e.preventDefault()

      topsoilColorElements.forEach((e) => e.classList.remove('topsoil-color--selected'))
      e.target.classList.add('topsoil-color--selected')

      const colorName = e.target.dataset.colorName
      const radioButton = document.querySelector("[value='" + colorName + "']")
      radioButton.checked = true
    })
  }
}

export default initTopsoilColors
