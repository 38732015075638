const initSmoothScroll = () => {
  // smooth scrolling
  function anchorLinkHandler(e) {
      const distanceToTop = el => Math.floor(el.getBoundingClientRect().top);

      e.preventDefault();
      const targetID = this.getAttribute("href");
      const targetAnchor = document.querySelector(targetID);
      if (!targetAnchor) return;
      const originalTop = distanceToTop(targetAnchor);

      window.scrollBy({ top: originalTop, left: 0, behavior: "smooth" });
  }

  const linksToAnchors = document.querySelectorAll('a[href^="#"]');
  for(let i = 0; i<linksToAnchors.length; i++) {
    const element = linksToAnchors[i]
    element.onclick = anchorLinkHandler
  }
}

export default initSmoothScroll
