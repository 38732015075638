const initTutorialCarousel = () => {
  const button_next = document.querySelector(".tutorial-carousel-next")
  const button_previous = document.querySelector(".tutorial-carousel-previous")
  const tutorial_carousel_items = document.querySelectorAll('.tutorial-carousel-item')

  if (button_next == null || button_previous == null || tutorial_carousel_items.length == 0) {
    return false
  }

  let current_index = 0

  button_next.addEventListener('click', (e) => {
    if (current_index+1 == tutorial_carousel_items.length) {
      current_index = 0
    } else {
      current_index++
    }

    tutorial_carousel_items.forEach((item) => {
      item.classList.add('is-hidden')
    })

    tutorial_carousel_items[current_index].classList.remove('is-hidden')
  })

  button_previous.addEventListener('click', (e) => {
    if (current_index-1 == -1) {
      current_index = tutorial_carousel_items.length-1
    } else {
      current_index--
    }

    tutorial_carousel_items.forEach((item) => {
      item.classList.add('is-hidden')
    })

    tutorial_carousel_items[current_index].classList.remove('is-hidden')
  })
}

export default initTutorialCarousel
