import { Controller } from "stimulus"
const Uppy = require("@uppy/core")
const uppyGerman = require("@uppy/locales/lib/de_DE")
const uppyEnglish = require("@uppy/locales/lib/en_US")
const uppyEspanol = require("@uppy/locales/lib/es_ES")
const Dashboard = require("@uppy/dashboard")
const DefaultStore = require("@uppy/store-default")
const XHRUpload = require('@uppy/xhr-upload')
import template from 'lodash/template'

export default class extends Controller {
  static values = { previousFiles: Array }

  connect() {
    const uppyElement = this.element

    if (uppyElement == undefined) {
      return false
    }

    const appLocale = document.querySelector("body").dataset.language
    let uppyLocale = uppyEnglish
    let uppyDropPaste = 'You can upload photos by drag-and-drop, copy-paste or click the %{browse} button. On a mobile device you can add photos from your gallery or the camera.'

    if(appLocale.toLowerCase() == "de") {
      uppyDropPaste = 'Die Bilder können über Drag/Drop, über die %{browse} oder über die Kamera eingefügt werden.'
      uppyLocale = uppyGerman
    }

    if(appLocale.toLowerCase() == "es") {
      uppyDropPaste = 'Las imágenes se pueden insertar al escritorio mediante arrastrar/soltar o copiar/pegar %{browse}. También se puede subir imágenes del teléfono móvil a través de la galería o la cámara.'
      uppyLocale = uppyEspanol
    }

    const uppy = Uppy({
      id: this.element,
      autoProceed: true,
      allowMultipleUploads: true,
      restrictions: {
        maxFileSize: 10*1024*1024,
        maxNumberOfFiles: uppyElement.dataset.maxNumberOfFiles,
        minNumberOfFiles: null,
        allowedFileTypes: ['image/jpeg', 'image/jpg']
      },
      meta: {},
      onBeforeFileAdded: (currentFile, files) => currentFile,
      onBeforeUpload: (files) => {},
      locale: uppyLocale,
      store: new DefaultStore(),
    })
    uppy.use(Dashboard, {
      id: 'Dashboard',
      locale: {
        strings: {
          dropPaste: uppyDropPaste,
        },
      },
      target: this.element,
      metaFields: [],
      inline: true,
      width: 750,
      height: 550,
      thumbnailWidth: 280,
      showLinkToFileUploadResult: true,
      showProgressDetails: false,
      hideUploadButton: false,
      hideRetryButton: false,
      hidePauseResumeButton: false,
      hideCancelButton: false,
      hideProgressAfterFinish: false,
      note: null,
      closeModalOnClickOutside: false,
      closeAfterFinish: false,
      disableStatusBar: false,
      disableInformer: false,
      disableThumbnailGenerator: false,
      disablePageScrollWhenModalOpen: true,
      animateOpenClose: true,
      proudlyDisplayPoweredByUppy: true,
      showSelectedFiles: true,
      browserBackButtonClose: false
    })

    uppy.use(XHRUpload, {
      endpoint: '/images/upload', // Shrine's upload endpoint
    })

    const form = this.element.closest("form")
    const submitButtons = form.querySelectorAll("[type='submit']")

    uppy.on('upload', (data) => {
      if (submitButtons.length > 0) {
        submitButtons.forEach( (submitButton) => {
          submitButton.setAttribute('disabled', true)
        })
      }
    })
    uppy.on('upload-success', (file, response) => {
      // read uploaded file data from the upload endpoint response
      var uploadedFileData = JSON.stringify(response.body)

      const hiddenInputTemplate = template( '<input type="hidden" name="soil_sample[soil_sample_images_attributes[]][image]" value="<%- fileData %>" data-uppy-target="template">' )
      const hiddenInput = hiddenInputTemplate({ fileData: JSON.stringify(uploadedFileData) })

      // set hidden field value to the uploaded file data so that it's submitted with the form as the attachment
      this.element.insertAdjacentHTML( 'beforeend', hiddenInput );
    })

    uppy.on('complete', function (result) {
      if (submitButtons.length > 0) {
        submitButtons.forEach( (submitButton) => {
          submitButton.removeAttribute('disabled')
        })
      }
    })

    this.previousFilesValue.forEach( (file) => {
      return fetch(file)
        .then((response) => response.blob()) // returns a Blob
        .then((blob) => {
            uppy.addFile({
              name: '809333d6e7f12a9365eda59bd3889329.jpg',
              type: blob.type,
              data: blob,
              remote: true,
            })

          uppy.getFiles().forEach( (file) => {
            uppy.setFileState(file.id, {
              progress: {
                uploadComplete: true,
                uploadStarted: true,
              }
            })
          })
        })
    })
  }
}
