import * as L from "leaflet"
import { GestureHandling } from "leaflet-gesture-handling"
import 'leaflet.markercluster'

const setLatLng = ({ lat, lng }) => {
  document.querySelector("#coords-latitude").value = lat
  document.querySelector("#coords-longitude").value = lng
}

const getPositionSuccess = (map, position) => {
  const getCurrentPositionWrapper = document.querySelector("[data-js-id='get-current-position']")  

  getCurrentPositionWrapper.querySelector(".spinner").classList.add('is-hidden')
  getCurrentPositionWrapper.querySelector(".crosshair-icon").classList.remove('is-hidden')
  document.querySelector(".get-current-position-error").classList.add('is-hidden')

  const latitude = position.coords.latitude
  const longitude = position.coords.longitude

  setLatLng({ lat: latitude, lng: longitude })

  map.setView(new L.LatLng(latitude, longitude), 15);
}

const getPositionFailure = (map, error) => {
  const getCurrentPositionWrapper = document.querySelector("[data-js-id='get-current-position']")  

  getCurrentPositionWrapper.querySelector(".spinner").classList.add('is-hidden')
  document.querySelector(".get-current-position-error").classList.remove('is-hidden')

  switch(error.code) {
    case error.PERMISSION_DENIED:
    case error.POSITION_UNAVAILABLE:
    case error.TIMEOUT:
      // user didn't accept the callout on time
      console.log("error!", error.code, error.message)
      break;
  }
}

const initializeMap = (selector) => {
  const mapContainer = document.querySelector(selector)

  const mapElement = L.DomUtil.get(mapContainer)

  if (mapElement !== null && mapElement._leaflet_id !== undefined) return false
  if (mapContainer == undefined) return false

  L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling)

  let mapZoom

  if (mapContainer.dataset !== undefined && mapContainer.dataset.zoom !== undefined) {
    mapZoom = mapContainer.dataset.zoom
  } else {
    mapZoom = 7
  }

  delete L.Icon.Default.prototype._getIconUrl;

  L.Icon.Default.mergeOptions({
    iconRetinaUrl: mapContainer.dataset.markerIcon2x,
    iconUrl: mapContainer.dataset.markerIcon,
    shadowUrl: mapContainer.dataset.shadowIcon,
    iconSize: [40,40],
    iconAnchor: [20,40],
    shadowSize: [40,40],
    shadowAnchor: [15,40],
  });

  const markers = []
  const markerClusterGroup = L.markerClusterGroup({
    maxClusterRadius: 60,
    // spiderfyDistanceMultiplier: 2,
  })

  const lat = mapContainer.dataset.latitude
  const lng = mapContainer.dataset.longitude

  if (lat !== undefined && lat != "" && lng != undefined && lng != "") {
    const coordinates = { coordinates: [mapContainer.dataset.latitude, mapContainer.dataset.longitude] }
    markers.push(coordinates)
  }

  if (mapContainer.dataset.markers !== undefined) {
    let parsedMarkers = JSON.parse(mapContainer.dataset.markers)
    markers.push(...parsedMarkers)
  }
  const map = L.map(mapContainer, {
    gestureHandling: true,
    gestureHandlingOptions: {
      duration: 750
    },
  })

  map.scrollWheelZoom.disable();
  map.setView([47.630213, 13.212463], 3)

  L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
    attribution: 'Card data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    useCache: true,
  }).addTo(map)

  let leafletMarkers = []

  markers.forEach((markerObject) => {
    const marker = L.marker(markerObject.coordinates)
    leafletMarkers.push(marker)

    if (markerObject.link != undefined && markerObject.title != undefined, markerObject.preview_image != undefined) {
      marker.bindPopup(
        `
          <img src="${markerObject.preview_image}" alt="${markerObject.title}">
          <a href="${markerObject.link}">${markerObject.title}</a>
        `
      )
    }

    marker.on('click', function (e) {
      map.setView(e.target.getLatLng());
    });

    markerClusterGroup.addLayer(marker)

    if (mapContainer.dataset.stickyMarker) {
      map.on("move", (a) => {
        const coordinates = map.getCenter()
        marker.setLatLng(coordinates)
        setLatLng({ lat: coordinates.lat, lng: coordinates.lng })
      })
    }
  })

  if (leafletMarkers.length > 0) {
    const markerGroup = new L.featureGroup(leafletMarkers)
    map.fitBounds(markerClusterGroup.getBounds(), { maxZoom: 16, padding: L.point(25, 25) })
  }

  map.addLayer(markerClusterGroup)
  return map
}

export default initializeMap
export { getPositionSuccess, getPositionFailure, setLatLng }
