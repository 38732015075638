import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "submit" ]

  reset() {
    this.element.reset()
  }

  disableSubmit() {
    this.submitTarget.setAttribute("disabled", true)
  }
}
