import { Controller } from "stimulus";
import Splide from "@splidejs/splide";

export default class extends Controller {
  static targets = [];
  static values = {
    i18n: Object,
  }

  connect() {
    this.splide = new Splide(this.element, {
      type: "slide",
      perPage: 4,
      perMove: 1,
      gap: "0.5rem",
      padding: {
        right: "3rem"
      },
      breakpoints: {
        640: {
          perPage: 2,
        },
        1400: {
          perPage: 3,
        },
      },
      i18n: this.i18nValue,
    }).mount();
  }

  disconnect() {
    this.splide.destroy();
  }
}
