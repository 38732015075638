const initVideo = () => {
  // video on landingpage
  const videoPoster = document.querySelector(".js-videoPoster")

  if (videoPoster == undefined || videoPoster.length == 0) {
    return false
  }

  videoPoster.addEventListener('click', (event) => {
    event.preventDefault();

    const poster = event.target;
    const wrapper = poster.closest('.js-videoWrapper');
    videoPlay(wrapper);
  })

  // play the targeted video (and hide the poster frame)
  function videoPlay(wrapper) {
    var iframe = wrapper.querySelector('.js-videoIframe');
    var src = iframe.dataset.src;
    // hide poster
    wrapper.classList.add('videoWrapperActive');
    // add iframe src in, starting the video
    iframe.setAttribute('src', src);
  }

  // stop the targeted/all videos (and re-instate the poster frames)
  function videoStop(wrapper) {
    // if we're stopping all videos on page
    if (!wrapper) {
      const wrapper = document.querySelector(".js-videoWrapper")
      const iframe = document.querySelector(".js-videoIframe")
    // if we're stopping a particular video
    } else {
      var iframe = wrapper.querySelector('.js-videoIframe');
    }
    // reveal poster
    wrapper.classList.remove('videoWrapperActive');
    // remove youtube link, stopping the video from playing in the background
    iframe.setAttribute('src','');
  }
}

export default initVideo
