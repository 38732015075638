const initSoilTypeTextField = () => {
  const soilTypeSelect = document.querySelector("#soil_sample_characteristic_soil_type")
  const soilTypeTextWrapper = document.querySelector("#soil-type-text")

  if (soilTypeSelect == undefined || soilTypeTextWrapper == undefined) {
    return false
  }

  const updateSoilTypeTextField = () => {
    if (soilTypeSelect.value == "identification_uncertain") {
      soilTypeTextWrapper.classList.remove('is-hidden')
    } else {
      soilTypeTextWrapper.classList.add('is-hidden')
    }
  }

  soilTypeSelect.addEventListener("change", (e) => {
    updateSoilTypeTextField()
  })

  updateSoilTypeTextField()
}

export default initSoilTypeTextField
