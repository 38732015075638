import flatpickr from 'flatpickr'
import flatpickrEnglish from 'flatpickr/dist/l10n/default'
import flatpickrGerman from 'flatpickr/dist/l10n/de'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'

const initFlatpickrMonthSelect = () => {

  const monthSelects = document.querySelectorAll("[data-monthselect]")
  const currentLanguage = document.querySelector("body").dataset.language
  let flatpickrLanguage;

  if (currentLanguage == "de") {
    flatpickrLanguage = flatpickrGerman.de
  } else {
    flatpickrLanguage = flatpickrEnglish.en
  }

  if (monthSelects != undefined) {
    for (let i = 0; i < monthSelects.length; i++) {
      const monthSelect = monthSelects[i]
      flatpickr(monthSelect, {
        locale: flatpickrLanguage,
        static: true,
        altInput: true,
        plugins: [new monthSelectPlugin({shorthand: false, dateFormat: "Y-m-d", altFormat: "F Y"})]
      })
    }
  }

}

export default initFlatpickrMonthSelect
